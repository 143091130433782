import { select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { currencyToFloat } from '@interco/cp-react-ui-lib'
import services from '@services'
import { ApplicationState } from '@store/types'

export default function* trackNewRelicSaga({ payload }: AnyAction) {
  const simulacao = yield* select((state: ApplicationState) => state.simulacao)
  const proposta = yield* select((state: ApplicationState) => state.proposta)
  const assinatura = yield* select((state: ApplicationState) => state.assinatura)
  const { newSimulatorApi, origem, familia } = yield* select(
    (s: ApplicationState) => s.ui.navigation,
  )
  try {
    yield services.postTrackNewRelic(
      {
        step: payload,
        numeroProposta: String(proposta.criacaoProposta.numeroProposta),
        estadoNaturalidade: proposta.nacionalidade.estadoSelecionado,
        cidadeNaturalidade: proposta.nacionalidade.cidadeSelecionada,
        estadoEndereco: proposta.endereco.estadoSelecionado,
        cidadeEndereco: proposta.endereco.cidadeSelecionada,
        tipoConta: proposta.dadosBancarios.tipoContaSelecionado,
        banco: proposta.dadosBancarios.bancoSelecionado,
        valorEmprestimo: currencyToFloat(assinatura.resumo.resumo.valorTotalASerPago),
        taxa: currencyToFloat(assinatura?.resumo.resumo.taxa),
        valorCET: assinatura?.resumo?.resumo.cet,
        valorLiberado: currencyToFloat(assinatura?.resumo.resumo.valorLiberado),
        creditoProtegido: assinatura?.resumo?.resumo.possuiSeguro,
        convenioId: simulacao?.idConvenio,
        antiFraude: proposta.dadosBancarios.contato,
        clientId: simulacao?.clientId,
        session: simulacao?.clientId,
        eventType: '',
        origem,
        familia,
      },
      newSimulatorApi,
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('​error', error)
  }
}
