import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import {
  buscarDocumentosSaga,
  carregarCapturaSaga,
  getOrgaos,
  postAtualizarPropostaSaga,
  postCapturaDocumentosSaga,
  postDocumentosAssinaturaSaga,
} from '@store/captura/documentos/sagas'
import { DocumentosAssinaturaTypes } from '@store/assinatura/documentosAssinatura/types'

import { DocumentosTypes } from './documentos/types'
import { FotoDocumentosTypes } from './fotoDocumentos/types'
import sendToS3PDFSaga from './fotoDocumentos/sagas/sendToS3PDF'
import { VideoSelfieTypes } from './videoSelfie/types'
import postFotoBiometriaLivenessSaga from './videoSelfie/sagas/postFotoBiometriaLivenessSaga'
import { InssDocumentosTypes } from './inssDocumentos/types'
import sendToS3PDFSagaInss from './inssDocumentos/sagas/sendToS3PDFInss'
import { ContrachequeDocumentosTypes } from './contrachequeDocumentos/types'
import sendToS3PDFSagaContracheque from './contrachequeDocumentos/sagas/sendToS3PDFContracheque'
import { postFotoDocumentoLivenessSaga } from './fotoDocumentos/sagas'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(DocumentosTypes.ORGAO_EXPEDIDOR_REQUEST, getOrgaos)
  yield* takeLatest(FotoDocumentosTypes.SEND_TO_S3_SAGA, sendToS3PDFSaga)
  yield* takeLatest(VideoSelfieTypes.POST_FOTO_BIOMETRIA_LIVENESS, postFotoBiometriaLivenessSaga)
  yield* takeLatest(DocumentosTypes.CAPTURA_DOCUMENTOS_REQUEST, postCapturaDocumentosSaga)
  yield* takeLatest(DocumentosTypes.CARREGAR_CAPTURA_REQUEST, carregarCapturaSaga)
  yield* takeLatest(DocumentosTypes.DOCUMENTOS_ASSINATURA_REQUEST, postDocumentosAssinaturaSaga)
  yield* takeLatest(DocumentosAssinaturaTypes.ATUALIZAR_PROPOSTA, postAtualizarPropostaSaga)
  yield* takeLatest(DocumentosTypes.BUSCAR_DOCUMENTOS_SAGA, buscarDocumentosSaga)
  yield* takeLatest(InssDocumentosTypes.SEND_TO_S3_SAGA_INSS, sendToS3PDFSagaInss)
  yield* takeLatest(
    ContrachequeDocumentosTypes.SEND_TO_S3_SAGA_CONTRACHEQUE,
    sendToS3PDFSagaContracheque,
  )
  yield* takeLatest(
    FotoDocumentosTypes.POST_FOTO_DOCUMENTOS_LIVENESS,
    postFotoDocumentoLivenessSaga,
  )
}
