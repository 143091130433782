import { action, Action } from 'typesafe-actions'

import { Anexo, InssDocumentosTypes, S3Type } from './types'

export const setInputFilesInss = (payload: string | number | undefined): Action =>
  action(InssDocumentosTypes.SET_INPUT_FILES_INSS, payload)

export const setAnexosInss = (payload: Anexo): Action =>
  action(InssDocumentosTypes.SET_ANEXOS_INSS, payload)

export const deleteAnexoInss = (payload: number): Action =>
  action(InssDocumentosTypes.DELETE_ANEXO_INSS, payload)

export const setIsDoneInss = (payload: boolean): Action =>
  action(InssDocumentosTypes.SET_IS_DONE_INSS, payload)

export const setIsLoadingInss = (payload: boolean): Action =>
  action(InssDocumentosTypes.SET_IS_LOADING_INSS, payload)

export const setIsErrorInss = (payload: boolean): Action =>
  action(InssDocumentosTypes.SET_IS_ERROR_INSS, payload)

export const sendToS3Inss = (payload: S3Type): Action =>
  action(InssDocumentosTypes.SEND_TO_S3_SAGA_INSS, payload)

export const setRollbackRouterErrorInss = (payload: string | number | undefined): Action =>
  action(InssDocumentosTypes.SET_ROLLBACK_ROUTER_ERROR_INSS, payload)
