import { put, select } from 'typed-redux-saga'

import services from '@services'
import { ErrorCodes } from '@utils/enums'
import { showError } from '@store/ui/error/actions'
import FilesService from '@services/FilesService'
import GeneratePdf from '@services/GeneratePdf'
import { ApplicationState } from '@store/types'
import { appendPdfs } from '@services/AppendPdf'

import { deleteAnexo, setIsDone, setIsError, setIsLoading } from '../actions'
import { Anexo } from '../types'

interface Props {
  payload: {
    anexos: Anexo[]
    tipo: string
    callback?: () => void
  }
  type: string
}
export default function* sendToS3PDFSaga({ payload }: Props) {
  const { cpf } = yield* select((s: ApplicationState) => s.simulacao)
  const tipoDocumentos = yield* select(
    (s: ApplicationState) => s.proposta.criacaoProposta.tipoDocumento,
  )
  const { newSimulatorApi, newVersion } = yield* select((s: ApplicationState) => s.ui.navigation)
  try {
    const pdfFileFromImg: File = yield GeneratePdf.generate(payload.anexos)
    const pdfFinal: File = yield appendPdfs(payload.anexos, pdfFileFromImg)
    const { data } = yield services.postCaptureUploadLink(
      {
        tamanho: pdfFinal.size,
        cpf,
        nome: `${payload.tipo}.pdf`, // Formato do arquivo é obrigatório no nome
        tipoDocumentoId:
          tipoDocumentos?.filter((tipo) => tipo.idTipoDocumento === payload.tipo)[0]
            ?.idTipoDocumento || payload.tipo,
      },
      newSimulatorApi,
    )
    yield FilesService.sendFileToS3(pdfFinal, data?.url)

    yield put(setIsDone(true))
    yield put(setIsLoading(false))
  } catch (error) {
    yield put(setIsError(true))
    yield put(setIsLoading(false))
    if (newVersion) {
      yield put(deleteAnexo(0))
      if (payload.callback) {
        yield payload.callback()
      }
    } else {
      yield* put(
        showError({
          title: 'Erro ao enviar o documento',
          message: 'Por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.C0_002_S3ENVIO,
        }),
      )
    }
  }
}
