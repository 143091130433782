/**
 * Actions Types
 */

export enum InssDocumentosTypes {
  SET_INPUT_FILES_INSS = '@captura/inssDocumentos/SET_INPUT_FILES',
  SET_ANEXOS_INSS = '@captura/inssDocumentos/SET_ANEXOS',
  DELETE_ANEXO_INSS = '@captura/inssDocumentos/DELETE_ANEXO',
  SET_IS_DONE_INSS = '@captura/inssDocumentos/SET_IS_DONE',
  SET_IS_LOADING_INSS = '@captura/inssDocumentos/SET_IS_LOADING',
  SET_IS_ERROR_INSS = '@captura/inssDocumentos/SET_IS_ERROR',
  SEND_TO_S3_SAGA_INSS = '@captura/inssDocumentos/SEND_TO_S3_SAGA',

  SET_ROLLBACK_ROUTER_ERROR_INSS = '@captura/inssDocumentos/SET_ROLLBACK_ROUTER_ERROR',
  RESET = 'RESET',
}

export interface BlobsType {
  height: number
  width: number
  data: string
}

export interface Anexo {
  anexo: {
    id: string
    nome: string
    tipoExtensao: string
    size?: number
    blobs: BlobsType[]
  }
  tipo: string
}

export interface S3Type {
  tipo: string
  anexos: Anexo[]
}
/**
 * State Type
 */

export interface InssDocumentosState {
  readonly inputFilesInss: string[]
  readonly anexosInss: Anexo[]
  readonly isDoneInss: boolean
  readonly isLoadingInss: boolean
  readonly isErrorInss: boolean
  readonly rollbackRouterErrorInss: string
  readonly indiceInss: number
}
