import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { ErrorCodes } from '@utils/enums'
import { showError } from '@store/ui/error/actions'
import FilesService from '@services/FilesService'
import GeneratePdf from '@services/GeneratePdf'
import { ApplicationState } from '@store/types'
import { appendPdfs } from '@services/AppendPdf'

import { setIsDoneInss, setIsErrorInss, setIsLoadingInss } from '../actions'
import { Anexo } from '../types'

interface Props {
  payload: {
    anexos: Anexo[]
    tipo: string
  }
  type: string
}
export default function* sendToS3PDFSagaInss({ payload }: Props) {
  const { cpf } = yield* select((s: ApplicationState) => s.simulacao)
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)

  try {
    const pdfFileFromImg: File = yield GeneratePdf.generate(payload.anexos)
    const pdfFinal: File = yield appendPdfs(payload.anexos, pdfFileFromImg)
    const response = yield* call(
      services.postCaptureUploadLink,
      {
        tamanho: pdfFinal.size,
        cpf,
        nome: `${payload.tipo}.pdf`,
        tipoDocumentoId: payload.tipo,
      },
      newSimulatorApi,
    )
    yield FilesService.sendFileToS3(pdfFinal, response?.data?.url)

    yield put(setIsDoneInss(true))
    yield put(setIsLoadingInss(false))
  } catch (error) {
    yield put(setIsErrorInss(true))
    yield put(setIsLoadingInss(false))
    yield* put(
      showError({
        title: 'Erro ao enviar o documento',
        message: 'Por favor tente novamente.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.C0_002_S3ENVIO,
      }),
    )
  }
}
