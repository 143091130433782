import { InfoValidaRequest } from '@services/postInfoValida'
import { SalvarPropostaRequest } from '@services/postSalvarProposta'
import { ApplicationState } from '@store/types'
import { ConveniosCodes } from '@utils/enums'

export const buildProposta = ({
  proposta,
  simulacao,
}: ApplicationState): SalvarPropostaRequest => ({
  clientId: simulacao.clientId && simulacao.clientId !== '' ? String(simulacao.clientId) : null,
  cpf: String(simulacao.cpf || ''),
  escolaridade: String(proposta.escolaridade.escolaridadeSelecionada || ''),
  profissao: String(proposta.escolaridade.profissaoSelecionada || ''),
  valorPatrimonio: Number(proposta.escolaridade.valuePatrimonio),
  pep: proposta.escolaridade.isPep,
  tin: proposta.escolaridade.isUsPerson,
  numeroTin: String(proposta.escolaridade.tinNumber || ''),
  banco: String(proposta.dadosBancarios.bancoSelecionado || ''),
  agencia: String(proposta.dadosBancarios.agencia || ''),
  conta: String(proposta.dadosBancarios.conta || ''),
  paisNaturalidade: proposta.nacionalidade.titlePais
    ? String(proposta.nacionalidade.titlePais)
    : 'Brasil',
  estadoNaturalidade: proposta.nacionalidade.estadoSelecionado
    ? String(proposta.nacionalidade.estadoSelecionado)
    : proposta.endereco.estadoSelecionado,
  cidadeNaturalidade: proposta.nacionalidade.cidadeSelecionada
    ? String(proposta.nacionalidade.cidadeSelecionada)
    : proposta.endereco.cidadeSelecionada,
  cep: String(proposta.endereco.cep || ''),
  endereco: String(proposta.endereco.logradouro || ''),
  numero: String(proposta.endereco.numero || ''),
  complemento: String(proposta.endereco.complemento || ''),
  bairro: String(proposta.endereco.bairro || ''),
  estado: String(proposta.endereco.estadoSelecionado || ''),
  cidade: String(proposta.endereco.cidadeSelecionada || ''),
  termoAceite: true,
  numeroProposta: String(proposta.criacaoProposta.numeroProposta || ''),
  tipoLiberacao:
    simulacao.idConvenio === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL
      ? 1
      : Number(proposta.dadosBancarios.tipoContaSelecionado),
  ipCliente: '0.0.0.0',
  digitoConta: Number(proposta.dadosBancarios.digito),
  simulacaoId: Number(simulacao.idSimulacao),
  codigoConvenio: Number(simulacao.idConvenio),
})

export const buildDocumentoId = ({ proposta }: ApplicationState): InfoValidaRequest => ({
  documentoId: String(proposta.criacaoProposta.documentoId || ''),
})
